import { Button, Col, Container, Row, Form, Spinner, Dropdown } from "react-bootstrap";
import PageLayout from "../components/PageLayout";
import { Trans } from "react-i18next";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { goTo } from "../utilities/utils";
import { saveRouteDetails } from "../redux/actions/routeActions";
import { useDispatch } from "react-redux";
import { graphql } from "gatsby";
import { RootState } from "../redux/reducers/rootReducer";
import { useSelector } from "react-redux";
import Modal from "../components/Modal";
import { api } from "../utilities/api";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCheck, faSquare } from "@fortawesome/free-regular-svg-icons";
import { MiniPlan } from "../types/MiniPlan";

export default function internalTransferConsent() {
  const dispatch = useDispatch();
  const shopDetails = useSelector((state: RootState) => state.shop);
  const [pageWidth, setPageWidth] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modalError, setModalError] = useState("");
  const [error, setError] = useState("");
  const isShowInfo = !loading;
  const [rid, setRID] = useState("");
  const [invalidRID, setInvalidRID] = useState(false);
  const [isEsim, setIsEsim] = useState(false);
  const [fullName, setFullName] = useState("");
  const [plans, setPlans] = useState<MiniPlan[]>([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [simNumber, setSIMNumber] = useState("");
  const [simNumConfirmed, setSIMNumConfirmed] = useState(false);
  const [selectedPlanID, setSelectedPlanID] = useState<number | undefined>(undefined);
  const [loadingNext, setLoadingNext] = useState(false);

  const DEFAULT_PLAN_OPTION = {
    PlanCode: "",
    PlanId: 0,
    PlanPrice: "",
    PlanCapacity: ""
  } as MiniPlan;
  const selectedPlan = plans.filter(plan => plan.PlanId === selectedPlanID)[0] ? plans.filter(plan => plan.PlanId === selectedPlanID)[0] : DEFAULT_PLAN_OPTION;

  const today = new Date();
  const isPastThe28th = today.getDate() > 28;

  const year = today.getFullYear();
  const month = today.getMonth();
  const parsedMonth = (month: number) => {
    let parsedMonth = "";
    let monthActual = month + 2 > 12 ? 1 : month + 2;
    parsedMonth = monthActual.toString();
    if (parsedMonth.length === 1) {
      parsedMonth = "0" + parsedMonth;
    }
    return parsedMonth;
  }

  const [activationDate, setActivationDate] = useState(new Date());
  const [minActivationDate, setMinActivationDate] = useState(new Date());
  const [maxActivationDate, setMaxActivationDate] = useState(new Date());

  const onClickOK = () => {
    if (invalidRID) {
      goTo("/");
    } else {
      setShowModal(false);
    }
  }

  const onChangeSelectedPlan = (plan: MiniPlan) => {
    if (plan.PlanId === selectedPlanID) {
      return;
    }
    setSelectedPlanID(plan.PlanId);
  }

  const onChangeSelectedActivationDate = (date: Date) => {
    console.log(date);
    if (date === activationDate) {
      return;
    }
    setActivationDate(date);
  }

  const onClickConfirmMigration = () => {
    if (!isEsim && !simNumConfirmed) {
      setError("You must confirm your SIM Number to proceed");
      return;
    }

    setLoadingNext(true);

    // var tzOffset = activationDate.getTimezoneOffset() * 60000;
    // var activationDateLocalISOTime = new Date((activationDate.getTime() - tzOffset)).toISOString();

    api.submitPortInConsentRequest(rid, {
      PlanId: selectedPlanID,
      Confirmed: true
    }).then(res => {
      setLoadingNext(false);
      setShowSuccessModal(true);
    }).catch(err => {
      setLoadingNext(false);
      setError(err);
      console.error(err);
    })
  }

  const onClickInformationIsNotCorrect = () => {
    setLoadingNext(true);

    // var tzOffset = activationDate.getTimezoneOffset() * 60000;
    // var activationDateLocalISOTime = new Date((activationDate.getTime() - tzOffset)).toISOString();

    api.submitPortInConsentRequest(rid, {
      PlanId: selectedPlanID,
      Confirmed: false
    }).then(res => {
      setLoadingNext(false);
      setShowFailureModal(true);
    }).catch(err => {
      setError(err);
      setLoadingNext(false);
      console.error(err);
    })
  }

  const handleEligibilityCheck = () => {
    let rid = new URLSearchParams(window.location.search).get("rid");

    let rid2 = window.location.href;
    console.log(rid2);
    if (rid) {
      // check that all spaces have been converted to pluses, due to URI encoding
      rid = rid.replace(/ /g, "+");
      setRID(rid);
      api.getPortInConsentStatus(rid)
        .then(res => {
          if (res.data) {
            let getPortInConsentStatusResponse = JSON.parse(res.data);
            if (!getPortInConsentStatusResponse.EffectiveDate || !getPortInConsentStatusResponse.Name) {
              // invalid RID
              setInvalidRID(true);
            } else {
              // check if plans are empty, it may still be an invalid offer.
              if (getPortInConsentStatusResponse.Plans.length === 0) {
                setInvalidRID(true);
                return;
              }

              // otherwise,
              // valid RID
              setPlans(getPortInConsentStatusResponse.Plans);
              setFullName(getPortInConsentStatusResponse.Name);
              setPhoneNumber(getPortInConsentStatusResponse.PhoneNumber);
              if (getPortInConsentStatusResponse.SIMType && getPortInConsentStatusResponse.SIMType === "ESIM") {
                setIsEsim(true);
              } else {
                setSIMNumber(getPortInConsentStatusResponse.SIMNumber);
                setIsEsim(false);
              }

              // find real selected plan
              let apiPlans = getPortInConsentStatusResponse.Plans;
              let apiSelectedPlan = getPortInConsentStatusResponse.PlanSelected;
              let searchResult = apiPlans.filter(plan => plan.PlanId === apiSelectedPlan);

              if (searchResult.length > 0) {
                setSelectedPlanID(apiSelectedPlan);
              } else {
                setSelectedPlanID(apiPlans[0].PlanId);
              }
            }
            setLoading(false);
          }
        })
        .catch(err => { 
          console.error(err);
          setLoading(false);
          setModalError("Sorry, something went wrong with checking your status.");
        });
    } else {
      goTo("/");
    }
  }

  const displayActivationDate = (date: Date) => {
    let utcMonth = date.getUTCMonth() + 1;
    return `${utcMonth}/${date.getUTCDate()}/${date.getUTCFullYear()}`
  }

  useEffect(() => {
    dispatch(saveRouteDetails("internal-transfer-consent") as any);
    handleEligibilityCheck();

    // add one to month if we are past the 28th. otherwise, no month increment needed.
    const activationMonthModifier = isPastThe28th ? 1 : 0;

    const earliestActivationDate = new Date(`${year}-${parsedMonth(month + activationMonthModifier)}-02`);
    const latestActivationDate = new Date(`${year}-${parsedMonth(month + 1 + activationMonthModifier)}-02`);

    setActivationDate(earliestActivationDate);
    setMinActivationDate(earliestActivationDate);
    setMaxActivationDate(latestActivationDate);
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
      setPageWidth(window.innerWidth);
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, [pageWidth]);

  return (
    <PageLayout showPartnerNav>
      <title>Plan Change Consent | PhoneBox</title>
      <Wrapper>
        <Container>
          <BackgroundDiv style={{ maxWidth: "900px", margin: "auto" }}>
            <BlueTitle className="p-2">
              {`Hi ${fullName},`}
            </BlueTitle>
            <Text>Your new plan is waiting!</Text>
            <Text>Please let us know when you would like to activate your new plan!</Text>
            {isShowInfo &&
              <div>
                <Row>
                  <Col>
                    <div className="p-2">
                      <Label bottomGap="16px">Phone Number</Label>
                      <Form.Control type="text" value={phoneNumber} disabled></Form.Control>
                    </div>
                  </Col>
                  {!isMobile &&
                    <Col />
                  }
                </Row>
                {!isEsim &&
                  <>
                    <Row>
                      <Col>
                        <div className="p-2">
                          <Label bottomGap="16px">SIM Number</Label>
                          <Form.Control type="text" value={simNumber} disabled></Form.Control>
                        </div>
                      </Col>
                      {!isMobile &&
                        <Col />
                      }
                    </Row>
                    <Row>
                      <div className="p-3 d-flex flex-row align-items-center">
                          {simNumConfirmed ? (
                            <FontAwesomeIcon style={{ color: '#333333', paddingLeft: 2 }} icon={faSquareCheck} fontSize={32} onClick={() => setSIMNumConfirmed(false)}></FontAwesomeIcon>
                          ) : (
                            <FontAwesomeIcon style={{ color: '#333333', paddingLeft: 2 }} icon={faSquare} fontSize={32} onClick={() => setSIMNumConfirmed(true)}></FontAwesomeIcon>
                          )}
                          <div className="p-2">
                            I have confirmed that my SIM Number (shown above) is correct.
                          </div>
                        </div>
                    </Row>
                  </>
                }
                <Row>
                  <Col>
                    <div className="p-2">
                      <Label bottomGap="16px">Selected Plan</Label>
                      <Dropdown>
                        <Dropdown.Toggle variant="primary">
                          {selectedPlan.PlanPrice === "" ? "Select a Plan" : `${selectedPlan.PlanCapacity} | $${selectedPlan.PlanPrice}`}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {plans.map((p, index) => (
                            <Dropdown.Item key={index} onClick={() => onChangeSelectedPlan(p)}>
                              <Trans>{`${p.PlanCapacity} | $${p.PlanPrice}`}</Trans>
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="p-2">
                      Your new plan will be effective in the next term. You will receive a SMS notification prior to the transfer. 
                    </div>
                  </Col>
                </Row>
                {/* <Row>
                  <Col>
                    <div className="p-2">
                      <Label bottomGap="16px">Activation Date</Label>
                      <Dropdown>
                        <Dropdown.Toggle variant="primary">
                          {displayActivationDate(activationDate)}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {[minActivationDate, maxActivationDate].map((date, index) => (
                            <Dropdown.Item key={index} onClick={() => onChangeSelectedActivationDate(date)}>
                              <Trans>{displayActivationDate(date)}</Trans>
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Col>
                </Row> */}
                {error.length > 0 &&
                  <CenteredDiv style={{ color: "red", marginBottom: "12px" }}>
                    {error}
                  </CenteredDiv>
                }
                <CenteredDiv style={{ marginTop: "12px", marginBottom: "12px" }}>
                  <div>If any of the information is not correct, <ClickableSpan onClick={onClickInformationIsNotCorrect}>please click here</ClickableSpan>.</div>
                </CenteredDiv>
                <CenteredDiv>
                  <CustomButton onClick={onClickConfirmMigration} disabled={loadingNext}>
                    {loadingNext && <Spinner variant="light" className="m-auto" animation="border" size="sm" />}
                    <span className="ms-2">
                      Confirm Your Plan Change
                    </span>
                  </CustomButton>
                </CenteredDiv>
                <CenteredDiv style={{ marginTop: "36px" }}>
                  <div>We will send you a SMS message notification to let you know we have received your submission.</div>
                  <div>For any questions or concerns, please contact our customer support:</div>
                </CenteredDiv>
                <CenteredDiv style={{ marginTop: "24px" }}>
                  <table style={{ width: "650px"}}>
                    <tbody>
                      <tr>
                        <StyledTD><span className="heavy-span">Email:</span> <a href="mailto:services@gophonebox.com">services@gophonebox.com</a></StyledTD>
                        <StyledTD><span className="heavy-span">Line:</span> phoneboxcanada</StyledTD>
                      </tr>
                      <tr>
                        <StyledTD><span className="heavy-span">Tel:</span> 855-886-0505</StyledTD>
                        <StyledTD><span className="heavy-span">Kakotalk:</span> phoneboxcanada</StyledTD>
                      </tr>
                      <tr>
                        <StyledTD><span className="heavy-span">WhatsApp:</span> 403-966-2412</StyledTD>
                        <StyledTD><span className="heavy-span">WeChat Public:</span> PhoneBox Official</StyledTD>
                      </tr>
                    </tbody>
                  </table>
                </CenteredDiv>
              </div>
            }
          </BackgroundDiv>
        </Container>
      </Wrapper>
      {showModal && 
        <Modal 
          onClose={() => onClickOK()}
          maxWidth={"600px"}
          body={
            <div style={{ padding: 10, marginLeft: 20, marginRight: 20 }}>
              {loading &&
                <>
                  <div className="d-flex align-items-center">
                    <Spinner variant="primary" className="m-auto" animation="border" />
                  </div>
                  <br/>
                  <div className="text-center">
                      <small>Checking your eligibility...</small>
                  </div>
                </>
              }
              {!loading &&
                <>
                  {invalidRID &&
                    <TextCenter>
                      <p>It seems like the link you are trying to access this page with is invalid.</p>
                      <p>Click "OK" to be returned to the home page.</p>
                    </TextCenter>
                  }
                  {!invalidRID &&
                    <TextCenter>
                      <p>Click "OK" to continue with the plan change process.</p>                    
                    </TextCenter>
                  }
                  {modalError.length > 0 &&
                    <TextCenter>
                      <p style={{ color: "red" }}>{modalError}</p>
                    </TextCenter>
                  }
                  <CenteredDiv style={{ marginTop: "12px" }}>
                    <Button variant="primary" onClick={onClickOK}>
                      OK
                    </Button>
                  </CenteredDiv>
                </>
              }
            </div>
          }
        />
      }
      {showFailureModal && 
        <Modal 
          onClose={() => goTo("/", {}, true)}
          maxWidth={"600px"}
          body={
            <div style={{ padding: 10, marginLeft: 20, marginRight: 20 }}>
              {!loading &&
                <>
                  <TextCenter>
                    <p>Thank you for your confirmation. Our agent will contact you shortly by email(services@gophonebox.com) or Toll Free number(1-855-886-0505).</p>
                    <p>Click "OK" or close the modal to be redirected to the home page.</p>
                  </TextCenter>
                  <CenteredDiv style={{ marginTop: "12px" }}>
                    <Button variant="primary" onClick={() => goTo("/", {}, true)}>
                      OK
                    </Button>
                  </CenteredDiv>
                </>
              }
            </div>
          }
        />
      }
      {showSuccessModal && 
        <Modal 
          onClose={() => goTo("/", {}, true)}
          maxWidth={"600px"}
          body={
            <div style={{ padding: 10, marginLeft: 20, marginRight: 20 }}>
              {!loading &&
                <>
                  <TextCenter>
                    <p>Thank you for your confirmation. A SMS message will be sent 24 hours before your transfer. Get your new sim card ready and enjoy your new plan!</p>
                    <p>Click "OK" or close the modal to be redirected to the home page.</p>
                  </TextCenter>
                  <CenteredDiv style={{ marginTop: "12px" }}>
                    <Button variant="primary" onClick={() => goTo("/", {}, true)}>
                      OK
                    </Button>
                  </CenteredDiv>
                </>
              }
            </div>
          }
        />
      }
    </PageLayout>
  )
}

const BlueTitle = styled.h3`
  font-weight: 600;
  color: #0494CA;
  font-size: 30px;
`

const Text = styled.div`
  margin: 12px 0px 12px 6px;
`

const Wrapper = styled.div`
  padding-top: 40px;
`;

const TextCenter = styled.div`
  text-align: center;
`

const CenteredDiv = styled.div`
  width: fit-content;
  margin: auto;
`

const BackgroundDiv = styled.div`
  background-color: #f8f8f8;
  padding: 24px;
  border-radius: 10px;
`

const Label = styled.div<{ topGap?: string; bottomGap?: string }>`
  font-size: 16px;
  color: black;
  font-weight: 500;
  margin-top: ${(props) => props.topGap};
  margin-bottom: ${(props) => props.bottomGap};
  width: max-content;
  span {
    color: red;
  }

  @media (max-width: 456px) {
    font-size: 17px;
  }
`;

const ClickableSpan = styled.span`
  text-decoration: underline;
  color: #0494CA;
  cursor: pointer;
`

const CustomButton = styled.button`
  font-size: 18px;
  font-weight: 400;
  transition: 0.7s;
  color: #fff;
  margin: 0px;
  outline: none !important;
  padding: 8px 40px;
  border-radius: 50px;
  display: inline-block;
  cursor: pointer;
  position: relative;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  background-image: linear-gradient(to bottom, #0194e9, #35c1d0);
  border: none;
  margin: 15px 0px;
  :hover {
    color: #fff;
  }
`;

const RequiredLabel = styled.p`
  font-size: 16px;
  color: black;
  font-weight: 500;
  :after {
    color: #e32;
    content: " *";
    display: inline;
  }

  @media (max-width: 418px) {
    font-size: 14px;
  }
`;

const StyledTD = styled.td`
  width: 50%;

  .heavy-span {
    font-weight: 600;
  }
`


export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

